button {
    cursor: pointer;
    position: relative;
    padding: 0;
    border: none;

    input {
        opacity: 0;
        height: 0 !important;
        position: absolute;
        padding: 0 !important;
        margin: 0 !important;
    }

    &[disabled] {
        color: $color-white;
        background-color: $color-grey;
        pointer-events: none;
    }
}

.btn {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1;    
    letter-spacing: 0.96px;
    max-width: 320px;
    width: 100%;
    height: 80px;
    border-radius: 12px;
    border: none;

    &_paint {
        color: $color-white;
        background-color: $color-blue-dark;
        &:hover {
            background-color: $color-blue-hover;
        }
    }

    &_transparent {
        color: $color-blue-dark;
        background-color: transparent;
        border: 2px solid $color-blue-dark;
        &:hover {
            color: $color-blue-hover;
            border-color: $color-blue-hover;
            background-color: rgba(4, 36, 158, 0.1);
        }
    }

    &_disabled {
        color: $color-white;
        pointer-events: none;
        background-color: $color-grey;
        border: none;
    }

    &_small {
        font-size: 16px;
        letter-spacing: 0.64px;
        width: 160px;
        height: 56px;
        border-radius: 8px;
    }

    &_ex-small {
        font-size: 16px;
        letter-spacing: 0.64px;
        width: 80px;
        height: 38px;
        border-radius: 8px;
    }
    &_height-big {
        height: 96px;
    }
    &_width-big {
        max-width: 400px;
        padding-top: 4px;
    }
    &_text {
        color: $color-blue-dark;
        background-color: transparent;
        border: none;
        svg {
            fill: $color-blue-dark;
        }
        &:hover {
            color: $color-blue-hover;
            svg {
                fill: $color-blue-hover;
            }
        }
    }
    &_download {
        width: auto;
        height: auto;
        max-width: max-content;
        border-radius: 0;
        display: flex;
        align-items: center;
        border: none;
        padding: 0;
        svg {
            width: 24px;
            fill: $color-blue-dark;
            margin-right: 8px;
        }
        &:hover {
            color: $color-blue-hover;
            background-color: transparent;
            svg {
                fill: $color-blue-hover;
            }
        }
    }
}

// button:not(.btn_paint):not(.btn_disabled):not(.btn_text):hover,
// a:hover,
// input[type="submit"]:hover {
//     opacity: .6;
// }
